@layer saraplus {
    $border-error: red;

    @keyframes border-fade-out {
        0% {
            border: 2px solid $border-error;
        }

        100% {
            border-bottom: 2px solid $border-error
        }
    }


    .sp-submitted .p-inputtext.ng-invalid {
        border-bottom: 2px solid $border-error !important;
        border-bottom-color: #d1d5db;
    }

    .p-element.ng-dirty.ng-invalid input.p-inputtext,
    .p-element.ng-touched.ng-invalid input.p-inputtext {
        border-bottom: 2px solid $border-error !important;
        border-bottom-color: $border-error;
    }

    .p-element.ng-dirty.ng-invalid .p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled,
    .p-element.ng-touched.ng-invalid .p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled,
    .p-element.ng-dirty.ng-invalid input.sp-invalid-animation.p-inputtext,
    .p-element.ng-touched.ng-invalid input.sp-invalid-animation.p-inputtext,
    .sp-invalid-animation.p-inputtext.p-component.p-element.ng-dirty.ng-invalid,
    .sp-invalid-animation.p-inputtext.p-component.p-element.ng-touched.ng-invalid {
        --bwidth: 1px;
        animation: border-fade-out 3000ms ease forwards;
        border-color: #d1d5db;
    }
}