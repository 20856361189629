.pi-plus-sara {
    content: url("./assets/icons/pi-plus-sara.svg");
}

.pi-search-sara {
    content: url("./assets/icons/pi-search-sara.svg");
}

.pi-email-red-sara {
    content: url("./assets/icons/pi-email-red-sara.svg");
}

.pi-email-green-sara {
    content: url("./assets/icons/pi-email-green-sara.svg");
}

.pi-email-green-sara-verified {
    content: url("./assets/icons/pi-email-green-sara-verified.svg");
}

.pi-email-yellow-sara {
    content: url("./assets/icons/pi-email-yellow-sara.svg");
}

.pi-att {
    content: url("./assets/icons/pi-att.png");
}

.pi-trash {
    content: url("./assets/icons/pi-trash.png");
}

.pi-wireless {
    content: url("./assets/icons/pi-wireless.png");
}

.pi-wireless-user {
    content: url("./assets/icons/pi-wireless-user.png");
}

.pi-directv {
    content: url("./assets/icons/pi-directv.png");
}

.pi-bundle-directv {
    content: url("./assets/icons/pi-bundle-directtv.png");
}

.pi-globe {
    content: url("./assets/icons/pi-globe.svg");
}

.pi-user-circle {
    content: url("./assets/icons/pi-user-circle.svg");
}

.pi-logout {
    content: url("./assets/icons/pi-logout.svg");
}

.pi-protection {
    content: url("./assets/icons/pi-protection.svg");
}

.pi-bundle-protection-small {
    content: url("./assets/icons/pi-bundle-protection-small.png");
}

.pi-bundle-globe {
    content: url("./assets/icons/pi-bundle-globe.svg");
}

.pi-bundle-customer-info {
    content: url("./assets/icons/pi-bundle-customer-info.svg");
}

.pi-bundle-wireless {
    content: url("./assets/icons/pi-bundle-wireless.svg");
}

.pi-bundle-video-small {
    content: url("./assets/icons/pi-bundle-video-small.svg");
}

.pi-bundle-att-wireless-small {
    content: url("./assets/icons/pi-bundle-att-wireless-small.svg");
}

.pi-bundle-video {
    content: url("./assets/icons/pi-bundle-video.svg");
}

.pi-bundle-green-check {
    content: url("./assets/icons/pi-bundle-green-check.svg");
}

.pi-bundle-green-check-small {
    content: url("./assets/icons/pi-bundle-green-check-small.svg");
}

.pi-bundle-exclamation-yellow {
    content: url("./assets/icons/pi-bundle-exclamation-yellow.svg");
}

.pi-bundle-exclamation-red {
    content: url("./assets/icons/pi-bundle-exclamation-red.svg");
}

.pi-bundle-exclamation-red-small {
    content: url("./assets/icons/pi-bundle-exclamation-red-small.svg");
}

.pi-bundle-minus {
    content: url("./assets/icons/pi-bundle-minus.svg");
}

.pi-bundle-minus-small {
    content: url("./assets/icons/pi-bundle-minus-small.svg");
}

.pi-bundle-cart {
    content: url("./assets/icons/pi-bundle-cart.svg");
}

.pi-bundle-empty-cart {
    content: url("./assets/icons/pi-bundle-empty-cart.svg");
}


.pi-bundle-comments {
    content: url("./assets/icons/pi-bundle-comments.svg");
}

.pi-bundle-navigation {
    content: url("./assets/icons/pi-bundle-navigation.svg");
}

.pi-bundle-sales-help {
    content: url("./assets/icons/pi-bundle-sales-help.svg");
}

.pi-bundle-disposition {
    content: url("./assets/icons/pi-bundle-disposition.svg");
}

.pi-bundle-att {
    content: url("./assets/icons/pi-bundle-att.png");
}

.pi-bundle-information-circle {
    content: url("./assets/icons/pi-bundle-information-circle.svg");
}

.pi-bundle-red-x {
    content: url("./assets/icons/pi-bundle-red-x.svg");
}

.pi-phone-validate {
    content: url("./assets/icons/pi-phone-validate.svg");
}

.pi-checkmark-circle {
    content: url("./assets/icons/pi-checkmark-circle.svg");
}

.pi-email-validate {
    content: url("./assets/icons/pi-email-validate.svg");
}

.pi-phone-green-sara-verified {
    content: url("./assets/icons/pi-phone-green-sara-verified.svg");
}

.pi-phone-green-sara {
    content: url("./assets/icons/pi-phone-green-sara.svg");
}

.pi-phone-red-sara {
    content: url("./assets/icons/pi-phone-red-sara.svg");
}

.pi-file-search {
    content: url("./assets/icons/pi-file-search.svg");
}

.pi-view-offer {
    content: url("./assets/icons/pi-view-offer.svg");
}

.pi-credit-info-collected {
    content: url("./assets/icons/pi-credit-info-collected.svg");
}

.pi-tools {
    content: url("./assets/icons/pi-tools.svg");
}

.pi-hour-glass {
    content: url("./assets/icons/pi-hour-glass.svg");
}

.pi-secure-data-link {
    content: url("./assets/icons/pi-secure-data-link.png");
}

.pi-secure-data-link-refresh {
    content: url("./assets/icons/pi-secure-data-link-refresh.svg");
}

.pi-circle-green-verified {
    content: url("./assets/icons/pi-circle-green-verified.svg");
}

.pi-credit-refresh {
    content: url("./assets/icons/pi-credit-refresh.svg");
}

.pi-external-link {
    content: url("./assets/icons/pi-external-link.svg");
}

.pi-live-support {
    content: url("./assets/icons/pi-live-support.svg");
}