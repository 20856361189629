$small: 767px;
$medium: 768px;
$large: 992px;
$xtralarge: 1200px;
$sara-blue: #237ec0;
$service-availability-blue: #0059B1;
$sara-header-blue : #0059b1;
$sara-primary-blue : #3A5FA5;
$sara-accordion-blue : #E0E9EF;
$toggle-switch-on : #008011;
$toggle-switch-off : #6C6C6C;
