@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("icons.scss");
@import "./variables.scss";

:root {
    --font-family: "Montserrat";
}

body {
    margin: 0;
    padding: 0;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 14px;
}

input {
    border-radius: 8px !important;
}

input.p-inputtext {
    margin-bottom: 0.5rem;
    height: 46px;
}

.p-inputtextarea {
    height: auto !important;
}

.p-toast {
    opacity: 1 !important;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
    margin-top: 0;
}

.p-toast .p-toast-message.p-toast-message-error {
    background: var(--red-100);
    border-color: var(--red-600);
}

.bg-blue-sara {
    color: var(--primary-color-text) !important;
    background-color: $sara-blue;
}

.w-saraplus-form {
    // @media (min-width: $xtralarge) {
    //     width: 33% !important;
    // }

    // @media (min-width: 1600px) {
    //     width: 25% !important;
    // }
}

.left-arrow {
    margin-right: 0 !important;
    text-decoration: none !important;
}

.right-arrow {
    margin-left: 0 !important;
    text-decoration: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.p-inputtext.p-inputotp-input {
    width: 50px;
    height: 50px;
}

.layout {
    .sidebar {
        width: 65px;
    }

    .main-container {
        width: -webkit-calc(100vw - 65px);
        width: -moz-calc(100vw - 65px);
        width: calc(100vw - 65px);
        position: absolute;
        left: 65px;
    }

    .header {
        width: -webkit-calc(100vw - 65px);
        width: -moz-calc(100vw - 65px);
        width: 100vw;
        padding-left: 5px !important;
    }
}

.layout.expanded-sidebar {
    .sidebar {
        width: 200px;
    }
}

@media screen and (max-width: $small) {
    .layout.collapsed-sidebar {
        .main-container {
            position: inherit;
        }
    }
}

.p-button-label {
    flex: none;
}

.p-button {
    justify-content: center !important;
    font-weight: 600 !important;
}

.p-dropdown {
    max-height: 46px;
    height: 46px;

    .p-dropdown-label.p-element.p-inputtext {
        display: flex;
        align-items: center !important;
    }
}

.skinny-dropdown,
.skinny {
    height: 46px !important;

    .p-dropdown-label.p-placeholder {
        align-content: normal !important;
        margin-bottom: 0.5rem;
    }

    .p-inputtext {
        height: 46px;
    }
}

label {
    color: #000;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 6px;
}

.hug-text {
    color: #343535;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 6px;
}

.p-radiobutton-label {
    color: $sara-blue !important;
    margin-bottom: 0px;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

.color-sara-blue {
    color: $sara-blue !important;
}

.button-disabled {
    color: white !important;
    background-color: #9e9e9e !important;
    border: 1px solid #9e9e9e !important;
    background: none;
}

.button-secondary {
    color: $sara-blue !important;
    background-color: white !important;
}

.p-placeholder {
    font-style: italic !important;
}

app-authorized-header h1 {
    font-size: 20px;
    font-weight: 600;
    margin-top: 0px !important;
    margin-bottom: 8px !important;
    color: #000;
}

app-authorized-header span {
    font-size: 16px;
    font-weight: 600;
    color: #343535;
}

.mb-12rem {
    margin-bottom: 12rem;
}

// italic place holders
::-webkit-input-placeholder {
    font-style: italic;
}

:-moz-placeholder {
    font-style: italic;
}

::-moz-placeholder {
    font-style: italic;
}

:-ms-input-placeholder {
    font-style: italic;
}

.p-dialog .p-dialog-content {
    padding: 0 2.5rem 2rem 2.5rem;
}

.mobile-hide {
    display: flex !important;
}

.mobile-show {
    display: none !important;
}

@media screen and (max-width: $small) {
    .mobile-hide {
        display: none !important;
    }

    .mobile-show {
        display: flex !important;
    }

    .layout .main-container {
        width: 100vw;
        width: 100vw;
        width: 100vw;
        left: 0;
        position: inherit;
    }
}

.font-bolder {
    font-weight: 800 !important;
}

.radio-border {
    width: 102px;
    border-radius: 0.5rem !important;
    border-width: 1px !important;
    border-style: solid;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-color: $sara-blue;
}

.border-round-lg {
    border-color: #00000033 !important;
}

.p-button.p-button-secondary {
    background: $sara-blue !important;
}

.sara-header-bg {
    background: $sara-header-blue !important;
}

// override p-radiobutton
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    background-color: $sara-blue !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
    background: none !important;
    box-shadow: none !important;
}

body.modal-open {
    overflow: hidden;
}

html,
body {
    overscroll-behavior: none;
}

.p-accordion-tab {
    margin-bottom: 8px !important;
    border-top: 0.5px solid lightgray;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
}

.p-accordion-content {
    border-bottom-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}

.p-accordion-header-link {
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
}

.p-accordion-tab .subheading {
    font-size: 16px !important;
    font-weight: 600 !important;
    padding-top: 24px !important;
    padding-bottom: 18px !important;
}

p-accordiontab h1 h2 h3 h4 h5 h6 {
    color: #000;
}

.header-text {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.p-error {
    font-size: 12px !important;
    line-height: 20px !important;
    color: #c80909;
    font-weight: 700 !important;

    i {
        font-style: normal !important;
    }
}

.header-bg-color {
    background-color: $sara-primary-blue;
}

// 16px or less line height will be 20px
// 17 - 20px line height will be 24px
.text-xs,
.text-sm,
.text-base {
    line-height: 20px !important;
}

.text-lg,
.text-xl {
    line-height: 24px !important;
}

.p-inputswitch-checked .p-inputswitch-slider {
    background: $toggle-switch-on !important;
}

.p-inputswitch-slider {
    background: $toggle-switch-off !important;
}

.sara-accordion-background {
    border-radius: 8px;
    background-color: $sara-accordion-blue !important;
}

.disabled-content {
    opacity: 0.6;
    pointer-events: none;
    user-select: none;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: not-allowed;
    }

    p,
    span {
        color: #666;
    }
}

.outline-none {
    outline: none !important;
    box-shadow: none !important;
}

.hidden-button {
    display: none;
}

.primary-text {
    color: var(--label-primary, #000);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.12px;
}

.secondary-text {
   @extend .primary-text;
   font-size: 14px;
}